<template>
  <v-main>
    <v-container fluid>
      <v-btn
        fab
        x-small
        class="mx-2 mb-2"
        color="white"
        @click="$router.back()"
      >
        <v-icon light> mdi-arrow-left </v-icon>
      </v-btn>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0 pb-0">
          <!-- Main Container -->
          <v-card
            class="mt-4 mb-0 pb-0"
            rounded="xl"
            color="blue lighten-5"
            width="auto"
          >
            <!-- Dashboard Container -->
            <v-container>
              <!-- <v-row class="ma-0"> -->
              <v-row class="ma-0" v-if="user.roles[0].name != 'Client'">
                <v-col class="pa-0" style="min-width: 250px"
                  ><p class="py-3 text-subtitle-1">
                    Add New Agreement and Compliances:
                  </p></v-col
                >
                <v-col class="pa-0">
                  <router-link to="/agreement-complaiances/create">
                    <v-btn color="white" dark fab>
                      <v-icon color="blue lighten1">mdi-plus</v-icon>
                    </v-btn>
                  </router-link>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col class="pa-0">
                  <v-row class="ma-0">
                    <v-col class="pa-0 mx-2">
                      <v-toolbar-title>Month: </v-toolbar-title>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0">
                    <v-col class="pa-0 mx-3">
                      <v-select
                        solo
                        dense
                        rounded
                        hide-details=""
                        v-model="selectedFromMonth"
                        :items="months"
                        label="Month"
                      ></v-select>
                      <!-- @change="updateCalendar" -->
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="pa-0">
                  <v-row class="ma-0">
                    <v-col class="pa-0 mx-2">
                      <v-toolbar-title>Year: </v-toolbar-title>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0">
                    <v-col class="pa-0 mx-2">
                      <v-select
                        solo
                        dense
                        rounded
                        hide-details=""
                        v-model="selectedYear"
                        :items="years"
                        label="Year"
                      ></v-select>
                      <!-- @change="updateCalendar" -->
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <hr class="mx-2 mt-5" />
              <template>
                <v-row justify="center" class="ma-0">
                  <v-col align="center" class="pa-0 mt-2">
                    <v-card rounded="xl">
                      <v-simple-table>
                        <thead>
                          <tr>
                            <th class="text-left">Sr</th>
                            <th class="text-left">Agreement/Compliance</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(agreement, us) in agreements"
                            :key="`agreement${us}`"
                          >
                            <td>{{ us + 1 }}</td>
                            <td>{{ agreement.document_path }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      selectedFromMonth: "",
      selectedToMonth: "",
      selectedYear: "",
      isLoading: false,
      agreements: [],
      months: [
        { text: "January", value: "01" },
        { text: "February", value: "02" },
        { text: "March", value: "03" },
        { text: "April", value: "04" },
        { text: "May", value: "05" },
        { text: "June", value: "06" },
        { text: "July", value: "07" },
        { text: "August", value: "08" },
        { text: "September", value: "09" },
        { text: "Octomber", value: "10" },
        { text: "November", value: "11" },
        { text: "December", value: "12" },
      ],
      years: [
        { text: "2022", value: "2022" },
        { text: "2023", value: "2023" },
        { text: "2024", value: "2024" },
      ],
      colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
      model: 0,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let agreements = await axios.get(`agreement_compliances`);
      this.agreements = agreements.data.data;
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.custom-border {
  border-color: #ff0000; /* Replace with your desired border color */
}
</style>
